import React, { useEffect, useState, useRef } from 'react';
import MeasurementUtils from 'appdir/lib/analytics';
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import EventsLink from 'shared/general/EventsLink';
import Button from 'shared/cms/Button';

// NOTE:  THIS BUTTON IS DIFFERENT FROM shared/general/Button

/**
 * -----------------------------------------------------------------------------
 * React Component: CalendarButton
 * props:
 *  name: String
 *  description: String (optional)
 *  startDate: "YYYY/MM/DD"
 *  endDate: "YYYY/MM/DD"
 *  startTime: "HH:MM"
 *  endTime: "HH:MM"
 *  label: String
 *  location: String
 *  options: "'Apple', 'Google', 'Yahoo', 'iCal'" (etc.)
 * -----------------------------------------------------------------------------
 */

const CalendarButton = (props) => {
	let attributes = props.data;

	logger.log('[CalendarButton] - props:%o', props);
	//logger.log('[CalendarButton] - attributes:%o', attributes);

	const [isMounted, setMounted] = useState(false);
	const [open, setOpen] = useState(false);
	const addToCalendarRef = useRef();

	
	/**
	 * So, several things happeneing here.  
	 * Adding a mutation observer for the rendered calendar button because it adds the last event from user to 
	 * a dom element attribute.  Problem is it doesn't work well, because you cannot capture the selected calendar
	 * type.  That is only showing in the google tag manager analytics.  If we ever get rid of that, we are screwed.
	 */

	useEffect(() => {
		//logger.log('[CalendarButton] - useEffect test:%o', "[]");
		setMounted(true);

		return () => {
			setMounted(false);
		}

	}, []);

	const event = {
		title: attributes?.name,
		description: attributes?.description,
		start: attributes?.start,
		end: attributes?.end,
		location: attributes?.location,
		busy: attributes?.busy
	};

	const onOpen = () => {
		logger.log('[CalendarButton] - ');
		MeasurementUtils.dispatchMeasurementCall('calendarButton', {
			open: !open
		});
		setOpen(!open);
}


	const getCalendarLink = (which) => {
		logger.log('[CalendarButton] getCalendarLink - which:%o, event:%o', which, event);

		switch (which) {
			case 'ics':
				return ics(event)	
			case 'google':
				return google(event)
			case 'outlook':
				return office365(event) // might need to switch outlook and office 365
			case 'office365':
				return outlook(event) // might need to switch outlook and office 365
			case 'yahoo':
				return yahoo(event)
			default:
				break;
		}
    }

	return (
		<div className="calendarButton-wrapper" ref={addToCalendarRef} >
			{isMounted && 
				<>
					<Button data={{
						buttonLink:{text:attributes?.label},
						callback: onOpen,
						style:"calendarButton buttonLink"
					}} />
					<div className={`calendarButton-options select-menu-primary-dropdown ${open ? 'show' : ''}`}>
						{attributes?.options?.map((option,i) => {
							const label = option?.split(':')[0].trim();
							const value = option?.split(':')[1].trim();
							return (
								<div key={i} className="option"><EventsLink to={getCalendarLink(value)} external><i className={`icon-${value}`} />{label}</EventsLink></div>
							)
						})}
					</div>
			
					
				</>
			
			}
		</div>
	)
};
export default CalendarButton;
