import React, { Component, useEffect } from 'react';
import EventsLink from 'shared/general/EventsLink';

/**
 * -----------------------------------------------------------------------------
 * React Component: Button
 * * type: CalendarButton
 * props:
 *  name: String
 *  description: String (optional)
 *  startDate: "YYYY/MM/DD"
 *  endDate: "YYYY/MM/DD"
 *  startTime: "HH:MM"
 *  endTime: "HH:MM"
 *  label: String
 *  location: String
 *  options: "'Apple', 'Google', 'Yahoo', 'iCal'" (etc.)
 * -----------------------------------------------------------------------------
 */

const Button = (props) => {
	let attributes = props.data;
	let button = props.data?.buttonLink;
	logger.log('[Button] - render - attributes: %o', attributes);
	logger.log('[Button] - render - button: %o', button);

	return (
		(button?.text && button?.url) ? (
			<section className={attributes.style}>
				<EventsLink to={`${button.url}`}>
					<button>{button.text}</button>
				</EventsLink>
			</section>
		) : (
			attributes?.callback ?
				<section className={attributes.style}>
					<button onClick={() => attributes?.callback()}>{button.text}</button>
				</section>
				: null
		)
	);
};
export default Button;
