/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import cn from 'classnames';
import op from 'object-path';
import EventsLink from 'shared/general/EventsLink';

import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: Button
 * -----------------------------------------------------------------------------
 */

class SelectMenu extends Component {
	// static getDerivedStateFromProps(nextProps, prevState) {
	// 	let selected = nextProps.selected;
	// 	if (!selected) {
	// 		selected = nextProps.attributes.options[0].value;
	// 	}

	// 	logger.log('[SelectMenu] getDerivedStateFromProps selected:%o', selected);

	// 	return {
	// 		selected: selected
	// 	};
	// }

	constructor(props) {
		super(props);

		//logger.log('[SelectMenu] constructor - props:%o', props);
		let selected = props.selected;
		if (!selected) {
			selected = props?.attributes?.options?.[0]?.value;
		}

		//logger.log('[SelectMenu] constructor selected:%o', selected);

		this.state = {
			...this.props,
			selected: selected,
		};
		logger.log('[SelectMenu] constructor this:%o', this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// logger.log('[SelectMenu] UNSAFE_componentWillReceiveProps - nextProps:%o', nextProps);

		let selected = nextProps.selected;
		if (!selected) {
			selected = nextProps?.attributes?.options?.[0]?.value;
		}

		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
				selected: selected,
			};
		});
	}

	getName(value) {
		let name = '';
		let options = op.get(this.state, 'attributes.options', []);

		if(options.length > 0) {
			options.forEach(item => {
				if (item.value == value) {
					//logger.log('[SelectMenu] getName - item:%o value:%o name:%o', item, value, item.name);
					name = item.name;
				}
			});
		}

		return name;
	}

	showMenu() {
		if (!this.state.show) {
			this.props.onOpen(this.state.name);
			MeasurementUtils.dispatchMeasurementCall('filterOpen', {previousSelected: this.state.selected});
		} else {
			// clear open value when show is false to avoid opening the dropdown on other state update
			this.props.onOpen('');

			MeasurementUtils.dispatchMeasurementCall('filterClose');
		}

		// logger.log('[SelectMenu] showMenu filter state:%o, state:%o, props:%o', this.state.name, this.state, this.props);

		this.setState({
			show: !this.state.show,
		});
	}

	setSelected(selected, name, type=null) {
		//logger.log('[SelectMenu] setSelected:%o', selected);
		this.setState({
			selected: selected,
			name: name,
			show: false,
			type: type ? type : null
		});
		this.props.onSelected(selected, name, type);
	}

	closeDropDown(length, value) {
		//logger.log('[DateFilter] - toggleDropDown event-length:%o,value:%o', length, value);
		if (value + 1 > length) {
			this.showMenu();
		}
	}

	render() {
		logger.log('[SelectMenu] render - state:%o', this.state);
		let arrow = ' ▾';
		let { attributes } = this.state;
		let title = this.getName(this.state.selected);
		// logger.log('[SelectMenu] render title:%o', title);
		/**
		 * select-menu klass options
		 * - select-menu-tabs2dropdown (default)
		 * - select-menu-dropdown2tabs
		 * - select-menu-primary -- dropdown onlly (larger dropdown title fonts over small breakpoint)
		 * - select-menu-tabs -- tabs onlly
		 */
		let klass = this.state.klass
			? this.state.klass
			: 'select-menu-tabs2dropdown';
		
		if(attributes) {
			let optionsLength = attributes.options ? attributes.options.length : 0;

			return (
				<div className={`${klass}`}>
					{/* {logger.log('[SelectMenu] render attributes:%o', this.state)} */}
					<button
						className={`title navigation_down_arrow ${
							this.state.show ? 'active' : ''
						} `}
						onClick={() => this.showMenu()}>
						{title}
					</button>
					<div
						className={`${klass}-dropdown ${
							this.state.show ? 'show' : ''
						}`}>
						{attributes.options.map(
							({ name, value, disabled, url, isLink, type }, index) => {
								// logger.log(
								// 	'[SelectMenu] display options map name:%o value:%o, disabled:%o',
								// 	name,
								// 	value,
								// 	disabled
								// );
								let classNames = () => {
									return cn({
										option: true,
										selected: this.state.selected === value,
										disabled: disabled,
									});
								};
								if (
									value &&
									value != 'section' &&
									(disabled == undefined || !disabled)
								) {
									return !isLink ? (
										<button
											key={name + index}
											className={classNames()}
											onClick={() =>
												this.state.selected === value
													? null
													: this.setSelected(value, name, type)
											}
											onBlur={() =>
												this.closeDropDown(
													optionsLength,
													index
												)
											}>
											<span className="option-name">
												{name}
											</span>
										</button>
									) : (
										<div key={name + index} className={classNames()}>
											{this.state.selected === value ? (
												<span className="option-name">
													{name}
												</span>
											) : (
												<EventsLink key={name} to={url} alt={name}>
													<span className="option-name">
														{name}
													</span>
												</EventsLink>
											)}
										</div>
									);
								} else if (
									value &&
									value != 'section' &&
									disabled
								) {
									return (
										<button key={name + index} className={classNames()}>
											<span className="option-name">
												{name}
											</span>
										</button>
									);
								} else if (disabled) {
									// add disabled class
									return (
										<div className="option disabled" key={name + index}>
											{name}
										</div>
									);
								} else {
									return (
										<div className="section" key={name + index}>
											{name}
										</div>
									);
								}
							}
						)}
					</div>
				</div>
			);
		} else {
			return null
		}	
	}
}

export default SelectMenu;
